<template>
	<div class="group-list">
		<!-- <van-sticky>
			<filter-top @get="getData"></filter-top>
		</van-sticky> -->

		<no-data v-if="goods_list.length<=0"></no-data>
		<van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh" v-else>
			<van-list finished-text="加载完毕" v-model="isloading" :finished="finished" @load="loadData">
				<div class="goodslist">

					<div class="goods-container" v-for="(item,index) in goods_list" :key="index">
						<div class="goods-wrap" @click="toDetail(item.id)">
							<van-cell title-class="top-cell flex flex-row">
								<template slot="title">

									<van-image width="3rem" height="3rem" :src="item.images[0]" fit="cover">
										<template v-slot:loading>
											<van-loading type="spinner" size="20" />
										</template>
									</van-image>


									<div class="text-wrap">
										<div class="name-wrap clamp-1">
											<span class="text-theme1" style="font-size: 14px;">{{item.good_name}}</span>
										</div>
										<div class="name-wrap clamp-1">
											<span class="text-base">{{item.description}}</span>
										</div>
										<div class="name-wrap clamp-1">
											<span class="text-base">最低成团：</span>
											<span class="text-base">{{item.minnumber}}人成团</span>
										</div>
										<div class="price-wrap clamp-1">
											<span class="text-theme1" style="font-size: 15px;color: red;margin-right: 10px;">￥0.01</span>
											<span class="text-grey">￥{{item.groupbuy_price}}</span>
										</div>
										
										
									</div>

								</template>
							</van-cell>



						</div>

					</div>

				</div>
			</van-list>
		</van-pull-refresh>
		
		<!-- 占位使用 -->
		<div style="padding-top: 3rem;"></div>
		<!-- <div class="Add-wrap" @click="toAdd">
			<van-icon name="add" />
		</div> -->
		<div class="hot-more-bottom" >
			<div class="hot-more-bottom-item" style="background-color: #26a2ff;" @click="toactivity()">查看活动详情</div>
			<div class="hot-more-bottom-item" style="background-color: #ff9900;" @click="myorder()">我的拼团订单</div>
		</div>
	</div>
</template>

<script>
	import {
		DropdownMenu,
		DropdownItem,
		List,
		Row,
		Col,
		Panel,
		Button,
		Search,
		Icon,
		Overlay,
		Picker,
		Popup,
		Field,
		Cell,
		CellGroup,
		DatetimePicker,
		Uploader,
		SwitchCell,
		Checkbox,
		Toast,
		Sticky,
		PullRefresh,
		Loading,
		Progress
	} from "vant";
	import { groupbuy_user_getgoodslist } from '../../../../request/api.js'
	export default {
		components: {
			[DropdownMenu.name]: DropdownMenu,
			[DropdownItem.name]: DropdownItem,
			[List.name]: List,
			[Row.name]: Row,
			[Col.name]: Col,
			[Panel.name]: Panel,
			[Button.name]: Button,
			[Search.name]: Search,
			[Icon.name]: Icon,
			[Overlay.name]: Overlay,
			[Popup.name]: Popup,
			[Field.name]: Field,
			[Picker.name]: Picker,
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
			[DatetimePicker.name]: DatetimePicker,
			[Uploader.name]: Uploader,
			[SwitchCell.name]: SwitchCell,
			[Checkbox.name]: Checkbox,
			[Toast.name]: Toast,
			[Sticky.name]: Sticky,
			[PullRefresh.name]: PullRefresh,
			[Loading.name]: Loading,
			[Progress.name]: Progress
		},
		data() {
			return {
				page: 1,
				size: 10,
				goods_list: [],
				isLoading: false,
				finished: false,
				isloading: false,
				activity_id: ''
			}
		},
		mounted(){
			this.activity_id = this.$route.query.id;
			this.loadData();
		},
		methods: {
			async loadData(){
			            if (this.finished) {
			                return;
			            }
			            this.isloading = true;
			            // 获取团购列表
			            await groupbuy_user_getgoodslist({
							activity: this.activity_id,
							size: this.size,
							}).then(res => {
			                if(res.code == 1){
			                    if (res.data.has_more == false){
			                        this.finished = true
			                    } else {
									this.size = this.size + this.size
									this.finished = false;
			                    }
			                    this.goods_list = res.data.data
								this.isloading = false
			                } else {
			                    this.$dialog({message: res.msg});
								this.loading = false;
								this.isloading = false
								this.finished = true
			                }
			            }).catch(err => {
							console.log(err)
							this.loading = false;
							this.isloading = false
							this.finished = true
			                this.$dialog({
			                    message: "团购数据获取失败，请重新刷新"
			                })
			            });
			
			            // 加载状态结束
			            this.loading = false;
						this.isloading = false
					},
			onRefresh(){
				this.isLoading = false
				this.finished = false
				this.goods_list = [];
				this.loadData();
			},
			toDetail(id){
				this.$router.push({path: '/activity/marketing/group/detail', query:{id: id, activity_id:this.activity_id}})
			},
			toactivity(){
				this.$router.push({path:'/activity',query:{id:this.activity_id}})
			},
			myorder(){
				this.$router.push({path: '/activity/marketing/group/order', query:{activity_id:this.activity_id}})
			}
		}
	}
</script>

<style lang="less" scoped>
	.group-list {
		.APP-field {
			width: 96%;
			padding: 2%;
			font-size: 12px;
			color: #757575;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
		}

		.APP-field>div {
			width: 47%;
			text-align: center;
			padding: 10px 0;
			border: 1px solid #e7e7e7;
		}

		.top-button-wrap {
			display: flex;
			flex-flow: row nowrap;
			padding: 10px 3px;
			justify-content: center;

			button {
				width: 100px;
			}
		}


		// 公共商品样式
		.goods-wrap {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			background-color: white;
			font-size: 0;
			overflow: hidden;

			.van-image {
				margin-right: 10px;
				border-radius: 5%;
			}

			.text-wrap {
				display: flex;
				flex-flow: column nowrap;
				justify-content: space-between;
				height: 3rem;
				flex: 1;

				font-size: 0;

				span {
					font-size: 12px;

				}

				.brand-wrap {
					flex: 1;
					// height: 17px;
				}

				.name-wrap {
					flex: 1;

					// height: 17px;
					.text-base {
						// font-size: 14px;
						// font-weight: bold
					}
				}

				.desc-wrap {
					// height: 35px;
					flex: 2;
					margin-bottom: 5px;
				}

				.price-wrap {
					// height: 17px;
					flex: 1;
					display: flex;
					flex-flow: row nowrap;
					align-items: center;

					.text-grey {
						text-decoration: line-through;
					}

					.text-theme3 {
						display: block;
						font-size: 18px;
						// margin-left: 5px;
					}
				}
			}


		}
		.Add-wrap {
		  position: fixed;
		  bottom: 20px;
		  right: 15px;
		  font-size: 45px;
		  color: #1989fa;
		}
	}
	
	.hot-more-bottom{
		position: fixed;
		bottom: 0;
		left: 0;
		display: flex;
		flex-flow: row nowrap;
		background-color: white;
		height: 40px;
		width: 100%;
		overflow: hidden;
		text-align:center;
		border-top:1px solid #e9e9e9;
		z-index: 889;
		div{
			font-size: 15px;
			width: 50%;
			color: white;
			line-height: 40px;
			display: inline-block;
		}
	}
</style>
